<template>
  <div class="tour-wrapper flex">
    <d-search :searchData="searchData" @searchEvent="searchList(1)" @resetEvent="reset"></d-search>
    <div class="inline-flex contentBox">
      <div class="contentTool">
        <el-button @click="addData" class=" float-right mgr10" type="primary" size="medium">新增区域</el-button>
      </div>
      <el-table :data="tableData" class="contentTable" size="medium" row-key="id" :tree-props="{children:'child',hasChildren:'hasChildren'}">
        <el-table-column prop="areaName" label="区域名称"></el-table-column>
        <el-table-column prop="updateTime" label="更新日期"></el-table-column>
        <el-table-column prop="updateUser" label="更新人员">
          <template slot-scope="scope">
            {{ scope.row.updateBySysUserName ? scope.row.updateBySysUserName.username : "无"}}
          </template>
        </el-table-column>
        <el-table-column  label="操作">
          <template  slot-scope="scope">
            <span class="mgr10 blue" v-if="scope.row.level!==3" @click="addChild(scope.row)">新增子类型</span>
            <span class="mgr10 blue" @click="edit(scope.row)">编辑</span>
            <span class="mgr10 colorRed" @click="delArea(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <d-paging :pager="pager" @change="searchList"></d-paging>


    <div class="dialog-wrapper">
      <el-dialog :title="editData.isEdit?'编辑':editData.type?'新增子区域':'新增区域'" :visible.sync="showEditModal" width="600px" >
        <el-form :model="editData" :rules="editRules" ref="tourAreaEditForm" size="medium">
          <el-form-item label="区域" prop="areaName">
            <el-input maxlength="20" v-model.trim="editData.areaName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input maxlength="80" type="textarea" max v-model="editData.remark" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button @click="showEditModal=false" size="medium">取消</el-button>
          <el-button  type="primary" @click="confirm" size="medium">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DPaging from "@/components/d-paging";
import {validateSpeCharacter} from "@/utils/validator";
export default {
  name: "tourAreaConfig",
  components: {DPaging, DSearch},
  data() {
    return {
      searchData: {
        searchItem: [
          {label: '区域', value: 'areaName', type: 'commonInput'}
        ],
        params: {
          areaName: ''
        },
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        }
      },
      tableData: [],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      showEditModal:false,
      editRules:{
        areaName:[
            this.$formValidator.empty('区域名称','blur'),
          {validator:validateSpeCharacter,trigger: 'blur'}
        ]
      },
      editData:{}
    }
  },
  watch:{
    'showEditModal'(val){
      if(val){
        this.$refs.tourAreaEditForm && this.$refs.tourAreaEditForm.clearValidate()
      }
    }
  },
  mounted(){
    this.searchList(1)
  },
  methods:{
    _getParams(){
      return {
        ...this.searchData.params,
        ...{
          pageNum: this.pager.page,
          pageSize: this.pager.rows,
        }
      }
    },

    // 查询列表
    searchList(page){
      page && (this.pager.page = 1,
          this.pager.rows = 10)
      this.$api.tour.areaConfList(this._getParams()).then(({data})=>{
        this.tableData = data.list;
        this.pager.count = data.total;
        this.setLevel(this.tableData,0);
      })
    },

    reset(){
      this.searchList(1)
    },

    // 设置层级
    setLevel(data,level){
      data.forEach(item=>{
        item.level  = level+1;
        if(item.child&&item.child.length){
          this.setLevel(item.child,item.level)
        }
        return item
      })
    },


    //  新增类型
    addData(){
      this.showEditModal = true;
      this.editData={
        isEdit:false,
        type:0
      }
    },

    //  新增子类型
    addChild(rowData){
      this.showEditModal = true;
      this.editData={
        isEdit:false,
        id:rowData.id,
        type:1
      };
    },

    edit(rowData){
      this.editData = JSON.parse(JSON.stringify(rowData));
      this.editData.isEdit = true;
      this.showEditModal = true;
    },

    delArea(rowData){
      this.$confirm('是否删除？','提示',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning'
      }).then(()=>{
        this.$api.tour.disableAreaConf({id:rowData.id}).then((data)=>{
          this.$message.success('操作成功');
          this.searchList(1)
        })
      }).catch(() => {})
    },

    confirm(){
      this.$refs.tourAreaEditForm.validate(valid=>{
        if(valid){
          let handler = null;
          let param = {
            id:this.editData.id||'',
            remark:this.editData.remark,
            areaName:this.editData.areaName,
          };
          if(this.editData.isEdit){
            handler = this.$api.tour.editAreaConf(param)
          }else{
            if(this.editData.type){
              handler = this.$api.tour.addChildAreaConf(param)
            }else{
              delete param.id
              handler = this.$api.tour.addAreaConf(param)
            }
          }
          handler.then(({code})=>{
            this.$message.success('操作成功')
            this.showEditModal  = false;
            for(let key in this.searchData.params){
              this.searchData.params[key] = ''
            }
            this.searchList(1)
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-table{
  flex: 1;
  overflow-y: auto;
}
</style>
